@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app,
.login,
.signup,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
  overflow: auto;
  /* background: linear-gradient(-45deg, #1e1e1e, #2c3e50, #34495e, #2c3e50); */
  /* background: linear-gradient(#1e1e1e,transparent); */
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

.center{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

